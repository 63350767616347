import React from 'react'
import PropTypes from 'prop-types'
import styles from './Section1.module.scss'
import { Link } from '@reach/router'
import { Fade } from 'react-reveal'
import HeadShake from 'react-reveal/HeadShake'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons'

const Section1 = props => {
  const { className, children, id } = props
  return (<div id={id} className={[className, styles.container, 'container'].join(' ')}>
    <div className={['row text-center', styles.centered].join(' ')}>
      <div className='col-md-12'>
        <Fade>
          {children}
        </Fade>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'>
            <HeadShake>
              <div className="card">
                <img className="card-img-top" src="https://i.ytimg.com/vi/0jwNAq9wBTQ/maxresdefault.jpg" alt="Card image cap"/>
                <div className="card-body">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <Link to="#">
                    <FontAwesomeIcon icon={faShareAlt}/>
                  </Link>
                </div>
              </div>
            </HeadShake>
          </div>
          <div className='col-md-4'>
            <HeadShake>
              <div className="card">
                <img className="card-img-top" src="https://i.ytimg.com/vi/0jwNAq9wBTQ/maxresdefault.jpg" alt="Card image cap"/>
                <div className="card-body">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <Link to="#">
                    <FontAwesomeIcon icon={faShareAlt}/>
                  </Link>
                </div>
              </div>
            </HeadShake>
          </div>
          <div className='col-md-4'>
            <HeadShake>
              <div className="card">
                <img className="card-img-top" src="https://i.ytimg.com/vi/0jwNAq9wBTQ/maxresdefault.jpg" alt="Card image cap"/>
                <div className="card-body">
                  <h5 className="card-title">Card title</h5>
                  <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  <Link to="#">
                    <FontAwesomeIcon icon={faShareAlt}/>
                  </Link>
                </div>
              </div>
            </HeadShake>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

Section1.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.any,
  id: PropTypes.string
}

export default Section1
