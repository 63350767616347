import React from 'react'
import PropTypes from 'prop-types'
import Layout from '@layouts/Layout'
import { useIntl } from 'gatsby-plugin-intl'
import { Section1 } from '@templates/news'
import Jumbotron from '@components/jumbotron/Jumbotron'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'

const News = props => {
  const { formatMessage } = useIntl()
  const { pageContext } = props
  const { languageName } = pageContext

  return <Layout languageName={languageName} pageContext={pageContext}>
    <Jumbotron
      icon={faNewspaper}
      title={formatMessage({ id: 'menu-news' })}
      description={formatMessage({ id: 'menu-contact-content' })}
    />
    <div className='row'>
      <Section1 className='col-md-12'>
          News
      </Section1>
    </div>
  </Layout>
}

News.propTypes = {
  pageContext: PropTypes.any
}

export default News
